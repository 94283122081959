import React from 'react';
import Layout from '../components/shared/layout'
import MaintenanceComponent from '../components/error/maintenance.component';

const ErrorPageComponent = ()=>{
    return(
      <Layout header={true} isBackBtn={true}>
        <MaintenanceComponent></MaintenanceComponent>
      </Layout>
    )
}

export default ErrorPageComponent;