import React, { Component } from 'react';
import Layout from '../components/shared/layout';
import PrivacyPolicyComponent from '../components/privacy-policy/privacy-policy.component';

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="tnc-page">
        <Layout header={true} isBackBtn={true}>
          <PrivacyPolicyComponent></PrivacyPolicyComponent>
        </Layout>
      </div>
    )
  }
}

export default PrivacyPolicy;