import React from 'react';
import PgNtFoundicon from '../../assets/images/page_not_found_icon.svg';

const PageNotFoundComponent = () => {
  const goBack = ()=>{
    window.history.back();
  }
  return (
    <div className="error-page">
        <div className="container">
          <h2><img src={PgNtFoundicon} alt="" width="45" /></h2>
          <h2>404 - Page not found</h2>
          <p>Sorry, the page you are looking for is no<br />longer here or wasn’t here in the first place.</p>
        </div>
        <div className="footer-fixed">
          <div className="container text-center">
            <button type="button" className="btn" onClick={()=>goBack()}><span>Back</span></button>
          </div>
        </div>
    </div>
  )
}

export default PageNotFoundComponent;