import React, { useState, useEffect } from "react";
import { navigate } from 'gatsby';
import * as constants from '../../app.constant';
import customerStore from "../../store/customerStore";
import validate, { isEmptyObject } from "./form-validation.component";
import useForm from "../use-form/use-form.component";
import translate from "../../i18n/translate";
import planStore from "../../store/planStore";
import * as EN from '../../i18n/messages/en-US';
import * as DE from '../../i18n/messages/de-DE';
import languageStore from '../../store/languageStore';
import mobileCoverPlus from '../../assets/pdf/bolttech_Mobile_Cover_and_Mobile_Cover_Plus_2021_01_22.pdf';
import mobileCoveAndMobileCoverPlus from '../../assets/pdf/BOLTTECH_Mobile-Cover_and_Mobile-Cover-Plus.pdf';
import mobileCoverDisplay from '../../assets/pdf/bolttech_Mobile_Cover_Display_2021_01_22.pdf';
import { LOCALES } from '../../i18n';

const ContactInformationFormComponent = () => {
  const [initialValues] = useState({
    customerFirstName: customerStore.customerFirstName,
    customerLastName: customerStore.customerLastName,
    customerEmail: customerStore.customerEmail,
    companyName: customerStore.companyName,
    customerPhoneNo: customerStore.customerPhoneNo,
    customerSecondaryPhoneNo: customerStore.customerSecondaryPhoneNo,
    street1: customerStore.street1,
    street2: customerStore.street2,
    zipCode: customerStore.zipCode,
    city: customerStore.city
  })

  const [touched, settouched] = useState([])

  const [userConsent, setuserConsent] = useState(false)

  const [isValid, setisValid] = useState(false)

  const [isSubmitting, setisSubmitting] = useState(false)

  const [attachementFileName, setAttachementFileName] = useState('');

  const [attachementFileUrl, setAttachementFileUrl] = useState('');


  const { handleChange, handleSubmit, values, errors, startValidate } = useForm(
    initialValues,
    validate,
    isSubmitting
  )

  const formSubmit = async e => {
    e.preventDefault()

    startValidate();

    if (!isEmptyObject(errors)) {
      setisSubmitting(false)
      focusOnFirstError()
    }
    setisSubmitting(true)
  }

  function focusOnFirstError() {
    if (!isEmptyObject(errors)) {
      let key = Object.keys(errors)[0]
      const errorEl = document.querySelector(`[name="${key}"]`)

      console.log(errorEl)

      if (errorEl && errorEl.focus) {
        errorEl.focus()
      }
    }
  }

  useEffect(() => {
    getEmailAttachementDetails();
  }, [])

  useEffect(() => {
    //chechk if form is valid and submitting
    console.log("use effect get called ", isEmptyObject(errors), isSubmitting);
    if (isEmptyObject(errors) && isSubmitting) {
      customerStore.updateCustomerDetail({ ...values });
      const currentRecepientId = `${customerStore.customerPhoneNo}${planStore.productId}`
      const sentArr = JSON.parse(localStorage.getItem("sentEmailList")) || [];
      console.log("sent arr ", sentArr, !sentArr.includes(currentRecepientId));
      if (!sentArr.includes(currentRecepientId)) sendNotification()
      //update customer store using the values passed from form
      navigate(constants.ROUTES.ORDER_SUMMARY)
    } else {
      focusOnFirstError()
    }
  }, [isSubmitting, errors])

  // check if field was touched
  const handleBlur = e => {
    if (isSubmitting) {
      //startValidate() 
    }
  }

  // check if required fields has value

  useEffect(() => {
    let requiredFields = fields
      .concat(addressfields)
      .filter(field => field.required && !field.disabled)
    let result = requiredFields.map(field =>
      values[field.name] != "" ? true : false
    )

    if (result.every(Boolean) && userConsent) {
      setisValid(true)
    } else {
      setisValid(false)
    }
  }, [values, userConsent])

  const getText = (keyName) => {
    if (keyName) {
      const keyArray = keyName.split('.');
      if (languageStore.selectedLanguage === LOCALES.ENGLISH) {
        return EN[keyArray[0]][keyArray[1]];
      } else {
        return DE[keyArray[0]][keyArray[1]];
      }
    }
  };

  const getEmailAttachementDetails = () => {
    let fileName, fileUrl;
    switch (planStore.productId) {
      case constants.PRODUCT_IDS.MOBILE_COVER_PLUS:
        fileName = constants.EMAIL_ATTACHEMENT.MOBILE_COVER_AND_PLUS_FILE_NAME;
        fileUrl = constants.EMAIL_ATTACHEMENT.MOBILE_COVER_AND_PLUS_URL;
        break;
      case constants.PRODUCT_IDS.MOBILE_COVER:
        fileName = constants.EMAIL_ATTACHEMENT.MOBILE_COVER_AND_PLUS_FILE_NAME;
        fileUrl = constants.EMAIL_ATTACHEMENT.MOBILE_COVER_AND_PLUS_URL;
        break;
      case constants.PRODUCT_IDS.MOBILE_COVER_PLUS_12M:
        fileName = constants.EMAIL_ATTACHEMENT.MOBILE_COVER_AND_PLUS_FILE_NAME;
        fileUrl = constants.EMAIL_ATTACHEMENT.MOBILE_COVER_AND_PLUS_URL;
        break;
      case constants.PRODUCT_IDS.MOBILE_COVER_12M:
        fileName = constants.EMAIL_ATTACHEMENT.MOBILE_COVER_AND_PLUS_FILE_NAME;
        fileUrl = constants.EMAIL_ATTACHEMENT.MOBILE_COVER_AND_PLUS_URL;
        break;
      case constants.PRODUCT_IDS.DISCOUNTED_DREI_MOBILE_COVER:
        fileName = constants.EMAIL_ATTACHEMENT.MOBILE_COVER_AND_PLUS_FILE_NAME;
        fileUrl = constants.EMAIL_ATTACHEMENT.MOBILE_COVER_AND_PLUS_URL;
        break;
      case constants.PRODUCT_IDS.DISCOUNTED_DREI_MOBILE_COVER_PLUS:
        fileName = constants.EMAIL_ATTACHEMENT.MOBILE_COVER_AND_PLUS_FILE_NAME;
        fileUrl = constants.EMAIL_ATTACHEMENT.MOBILE_COVER_AND_PLUS_URL;
        break;

      default:
        break;
    }
    setAttachementFileName(fileName);
    setAttachementFileUrl(fileUrl);
  }

  const sendNotification = async () => {
    const url = process.env.SEND_NOTIFICATION_URL
    const body = {
      attachmentFileName: attachementFileName,
      attachmentUrl: attachementFileUrl,
      templateName: constants.EMAIL.TEMPLATE_NAME,
      subject: constants.EMAIL.SUBJECT,
      to: [customerStore.customerEmail],
      status: 'true',
      from: constants.EMAIL.FROM,
      data: {
        customerFirstName: customerStore.customerFirstName,
        customerLastName: customerStore.customerLastName,
        productName: (getText(planStore.productName)).replace('<br/>', ' ')
      }
    }
    const method_options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.NOTIFICATION_API_KEY
      },
      body: JSON.stringify(body)
    }
    await fetch(url, method_options)
      .then(res => res.json())
      .then(response => {
        if (!response.error) {
          let sentArr = JSON.parse(localStorage.getItem('sentEmailList')) || [];
          sentArr.push(`${customerStore.customerPhoneNo}${planStore.productId}`)  //added unique id into localstorage to avoid spamming
          localStorage.setItem('sentEmailList', JSON.stringify(sentArr));
          console.log("mail has been sent to the customer", response);
        }
        else {
          console.log("error in sending email", response);
        }
      })
  }

  const [fields, setfields] = useState([
    {
      name: "customerFirstName",
      label: "Name",
      required: true,
      halfwidth: true,
      disabled: false,
      value: "gautam",
    },
    {
      name: "customerLastName",
      label: "Surname",
      required: true,
      optional: false,
      halfwidth: true,
      disabled: false,
    },
    {
      name: "companyName",
      label: "Company Name",
      required: false,
      disabled: false,
    },
    {
      name: "customerEmail",
      label: "Email",
      required: true,
      disabled: false,
    },

    {
      name: "customerPhoneNo",
      label: "Mobile Number",
      required: true,
      disabled: true,
    },
    {
      name: "customerSecondaryPhoneNo",
      label: "Secondary contact number",
      required: false,
      disabled: false
    },
  ])

  const [addressfields, setAddressFields] = useState([
    {
      name: "street1",
      label: "Street 1",
      required: true,
      disabled: false,
    },
    {
      name: "street2",
      label: "Street 2",
      required: false,
      disabled: false,
    },
    {
      name: "zipCode",
      label: "Postal Code",
      required: true,
      halfwidth: true,
      disabled: false,
      type: "number",
    },
    {
      name: "city",
      label: "City",
      required: true,
      halfwidth: true,
      disabled: false,
    },
  ])


  const [pdfUrl, setPdfUrl] = useState('');
  useEffect(() => {
    getPdfUrl();
  }, [])
  const getPdfUrl = () => {
    switch (planStore.productId) {
      case constants.PRODUCT_IDS.MOBILE_COVER_PLUS:
        setPdfUrl(mobileCoveAndMobileCoverPlus);
        break;
      case constants.PRODUCT_IDS.MOBILE_COVER_PLUS_12M:
        setPdfUrl(mobileCoveAndMobileCoverPlus);
        break;
      case constants.PRODUCT_IDS.MOBILE_COVER:
        setPdfUrl(mobileCoveAndMobileCoverPlus);
        break;
      case constants.PRODUCT_IDS.MOBILE_COVER_12M:
        setPdfUrl(mobileCoveAndMobileCoverPlus);
        break;
      case constants.PRODUCT_IDS.MOBILE_COVER_12M:
        setPdfUrl(mobileCoveAndMobileCoverPlus);
        break;
      case constants.PRODUCT_IDS.MOBILE_COVER_12M:
        setPdfUrl(mobileCoveAndMobileCoverPlus);
        break;
      case constants.PRODUCT_IDS.MOBILE_COVER_12M:
        setPdfUrl(mobileCoveAndMobileCoverPlus);
        break;
      case constants.PRODUCT_IDS.DISCOUNTED_DREI_MOBILE_COVER:
        setPdfUrl(mobileCoveAndMobileCoverPlus);
        break;
      case constants.PRODUCT_IDS.DISCOUNTED_DREI_MOBILE_COVER_PLUS:
        setPdfUrl(mobileCoveAndMobileCoverPlus);
        break;

      default:
        break;
    }
  }


  return (
    <div className="form-wrapper">
      <div className="page-header">{translate("contactinfo.form1.header")}</div>
      <form
        onSubmit={formSubmit}
        className="form"
        noValidate
        name="customerForm"
      >
        <div className="row">
          {fields.map(field => {
            const { name, type, disabled } = field
            return (
              <div
                key={name}
                className={`form-group ${field.disabled ? "disabled" : ""} ${errors[name] ? "error" : ""
                  } ${field.halfwidth ? "col-lg-6" : "col-lg-12"}`}
              >
                <label className="form-field-label" htmlFor={name}>
                  {translate("contact.form." + field.name)}
                  {field.required ? (
                    ""
                  ) : (
                      <small>* {translate("form.optional")}</small>
                    )}
                </label>
                <input
                  disabled={disabled}
                  onChange={handleChange}
                  className={errors[name] ? "form-control form-control-error" : "form-control"}
                  name={name}
                  value={values[name]}
                  type={type != null ? type : "text"}
                  placeholder=""
                  onBlur={handleBlur}
                />
                {errors[name] && (
                  <>
                    <span className="error-icon"></span>
                    <span className="error-message">{errors[name]}</span>
                  </>
                )}
              </div>
            )
          })}
        </div>

        <div className="address-form">
          <div className="page-header">
            {translate("contactinfo.form2.header")}
          </div>
          <div className="form-group">
            <div className="row">
              {addressfields.map(field => {
                const { name, type } = field
                return (
                  <div
                    key={name}
                    className={`
                      ${errors[name] ? "error" : ""}
                      ${field.halfwidth
                        ? "form-group inline-flex col-lg-6"
                        : "form-group col-lg-12"
                      }
                      `}
                  >
                    <label
                      className="form-field-label capitalize"
                      htmlFor="brand"
                    >
                      {translate("contact.form." + field.name)}
                      {field.required ? (
                        ""
                      ) : (
                          <small>* {translate("form.optional")}</small>
                        )}
                    </label>
                    <input
                      required
                      onChange={handleChange}
                      className={errors[name] ? "form-control form-control-error" : "form-control"}
                      name={name}
                      value={values[name]}
                      type={type != null ? type : "text"}
                      placeholder=""
                      onBlur={handleBlur}
                    />
                    {errors[name] && (
                      <>
                        <span className="error-icon"></span>
                        <span className="error-message">{errors[name]}</span>
                      </>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div>
          <div className="checkbox-wrapper">
            <input
              onClick={() => setuserConsent(!userConsent)}
              onChange={handleChange}
              className="checkbox"
              name="userConsent"
              id="userConsent"
              value={userConsent}
              onBlur={handleBlur}
              type="checkbox"
            />
            <span className="checkmark"></span>
            <label htmlFor="userConsent">
              Ich bestätige, dass mir die <a href={pdfUrl} download>AVBs und die Datenschutzerklärung</a> zur Verfügung gestellt wurden.
              </label>
          </div>
          {errors["consent"] && (
            <div className="error-message">{errors["consent"]}</div>
          )}
        </div>

        <div className="form-cta-wrapper">
          <button type="submit" className="btn btn-block" disabled={!isValid}>
            <span>{translate("form.cta.continue")}</span>
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactInformationFormComponent
