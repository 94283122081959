import React from 'react';
import Layout from '../components/shared/layout';
import DisclaimerComponent from '../components/terms-and-conditions/disclaimer.component';


const DisclaimerPage = () => {
  return (
    <div className="tnc-page">
      <Layout header={true} isBackBtn={true} footer={true}>
        <DisclaimerComponent></DisclaimerComponent>
      </Layout>
    </div>
  )
}

export default DisclaimerPage;