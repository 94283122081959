import React from 'react';
import maintenancePageIcon from '../../assets/images/maintenance_page_icon.svg';

const MaintenanceComponent = () => {
  const goBack = ()=>{
    window.history.back();
  }
  return (
    <div className="error-page">
        <div className="container">
          <h2><img src={maintenancePageIcon} alt="" width="45" /></h2>
          <h2>Repairs in progress...</h2>
          <p>We're sorry for the inconvenience.<br />Please come back later.</p>
        </div>
        <div className="footer-fixed">
          <div className="container text-center">
            <button type="button" className="btn" onClick={()=>goBack()}><span>Back</span></button>
          </div>
        </div>
    </div>
  )
}

export default MaintenanceComponent;