import { useState, useEffect } from "react"
import { isEmptyObject } from "../contact-info/form-validation.component"

const useForm = (initialValues, validate,) => {
  const [values, setValues] = useState(initialValues)

  const [errors, setErrors] = useState({})

  const [firstSubmit, setfirstSubmit] = useState(false)

  const handleChange = e => {
    const { name, value } = e.target

    setValues({
      ...values,
      [name]: value,
    });

    if(firstSubmit) startValidate()
  }

  const handleSubmit = e => {
    setfirstSubmit(true)
    startValidate()
  }

  const startValidate = e => {
    setErrors(validate(values))
  }

  useEffect(() => {
    firstSubmit && startValidate()
  }, [values, firstSubmit])

  return {
    handleChange,
    handleSubmit,
    values,
    startValidate,
    errors
  }
}

export default useForm
