import React from "react";
import Layout from "../components/shared/layout";
import FormLayoutComponent from '../components/form-layout/form-layout.component';
import DeviceInfoFormComponent from "../components/device-info/device-info-form.component";

const DeviceDetailsPage = () => {
  return (
    <Layout header={true} footer={true} isBackBtn={true}>
      <FormLayoutComponent route="device-info">
        <DeviceInfoFormComponent />
      </FormLayoutComponent>
    </Layout>
  )
}

export default DeviceDetailsPage
