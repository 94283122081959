import React, { useState } from "react"
import deviceStore from "../../store/deviceStore"
import customerStore from "../../store/customerStore"
import { navigate } from 'gatsby';
import * as constants from '../../app.constant';
import translate from "../../i18n/translate";

const DeviceInfoFormComponent = () => {
  const [fields, setfields] = useState([
    {
      name: "brand",
      label: "Brand",
      value: deviceStore.deviceMake,
    },
    {
      name: "model",
      label: "Model",
      value: deviceStore.deviceModel,
    },
    {
      name: "dateofPurchase",
      label: "Date of Purchase",
      value: deviceStore.deviceActivationDate,
    },

    {
      name: "customerPhoneNo",
      label: "Mobile Number",
      value: customerStore.customerPhoneNo,
    },
    {
      name: "imei",
      label: "IMEI",
      value: deviceStore.deviceImei,
    },
  ])

  const goToContactInfoPage = () => {
    navigate(constants.ROUTES.CONTACT_INFO);
  }

  return (
    <div className="form-wrapper">
      <div className="page-header">{translate('deviceinfo.heading')}</div>
      {fields.map(field => {
        return (
          <div className="form-group disabled" key={field.name}>
            <label className="form-field-label" htmlFor={field.name}>
              {translate("deviceinfo.form." + field.name)}
            </label>
            <input
              disabled={true}
              className="form-control"
              name="brand"
              value={field.value}
              type="text"
              placeholder=""
            />
          </div>
        )
      })}
      <div className="form-cta-wrapper" >
        <button type="button" className="btn btn-block" onClick={goToContactInfoPage}>
          <span>{translate('form.cta.continue')}</span>
        </button>
      </div>
    </div>
  )
}

export default DeviceInfoFormComponent
