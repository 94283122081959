import React from 'react';
import Layout from '../components/shared/layout'
import PageNotFoundComponent from '../components/error/pageNotFound.component';

const ErrorPageComponent = ()=>{
    return(
      <Layout header={true} isBackBtn={true}>
        <PageNotFoundComponent></PageNotFoundComponent>
      </Layout>
    )
}

export default ErrorPageComponent;